<template>
  <div class="signin_container">
    <div class="signin_form">
      <div class="signin_form_container">
        <div class="authorization">
          <div class="authorization_form">
            <div class="authorization_label">{{ $t('Registration') }}</div>
            <a v-if="$i18n.locale=='ru'" target="_blank" href="/documents/signup/Инструкция по регистрации на онлайн платформу Birge Oqy (ru).pdf" class="instructions-link">{{ $t('instructions')}}</a>
            <a v-else  target="_blank" href="/documents/signup/Birge Oqy онлайн платформасына тіркелу жөніндегі нұсқаулық (kz).pdf" class="instructions-link">{{ $t('instructions')}}</a>
            <router-link :to="{ name: 'faq-item', params: { id: 18 } }" class="tariff_details">{{ $t('Tariff_details')}}</router-link>
            <div class="authorization_inputs">
              <div class="input_iin_container">
                <div :class="[account.last_name.length > 0 ? activeClass : '', basicClass]">
                  <input class="input_iin" v-model="account.last_name" :placeholder="$t('Surname')"
                         @blur="$v.account.last_name.$touch()"/>
                </div>
                <span v-if="$v.account.last_name.$dirty && $v.account.last_name.$invalid" class="text-danger">{{
                    $t('Last_name_required')
                  }}!</span>
              </div>
              <div class="input_iin_container">
                <div :class="[account.first_name.length > 0 ? activeClass : '', basicClass]">
                  <input class="input_iin" v-model="account.first_name" :placeholder="$t('Name')" @blur="$v.account.first_name.$touch()"/>
                </div>
                <span v-if="$v.account.first_name.$dirty && $v.account.first_name.$invalid" class="text-danger">{{ $t('Name_required') }}!</span>
              </div>
              <div class="input_iin_container">
                <input class="input_iin" v-model="account.iin" :placeholder="$t('IIN')" @input="showIINError = false" maxlength="12"/>
                <span v-if="$v.account.iin.$dirty && $v.account.iin.$invalid" class="text-danger">{{
                    $t('IIN_required')
                  }}!</span>
                <span v-if="showIINError" class="text-danger">{{this.errorsMessages.iin && this.errorsMessages.iin[0]}}</span>
              </div>
              <div class="input_iin_container">
                <div :class="[account.email.length > 0 ? activeClass : '', basicClass]">
                  <input class="input_iin" v-model.trim="account.email" :placeholder="$t('Email')" @input="showEmailError = false" @blur="$v.account.email.$touch()"/>
                </div>



                <span v-if="$v.account.email.$dirty && $v.account.email.$invalid" class="text-danger">{{$t('Enter_valid_email') }}!</span>
                <span v-if="showEmailError" class="text-danger">{{this.errorsMessages.email && this.errorsMessages.email[0]}}</span>


              </div>
              <div class="input_iin_container">
                <div :class="[account.phone.length > 0 ? activeClass : '', basicClass]">
                  <input class="input_iin"
                     v-model="account.phone"
                     placeholder="+7 (7XX)–XXX–XXXX"
                     v-mask="'+7 (7##)-###-####'"
                     @input="enforcePhoneFormat"
                     @blur="$v.account.phone.$touch()"
                  />
                </div>
                <span v-if="showPhoneError" class="text-danger">{{this.errorsMessages.phone && this.errorsMessages.phone[0]}}</span>
              </div>
              <div class="input_iin_container">
                <select class="input_iin"
                        :class="[account.notification_language.length > 0 ? activeNotificationClass : '', basicNotificationClass]"
                        v-model="account.notification_language"
                >
                  <option
                    disabled
                    :label="$t('Choose_language')">
                  </option>
                  <option
                    key="kk"
                    :label="$t('Kazakh')"
                    value="kz">
                  </option>
                  <option
                    key="ru"
                    :label="$t('Russian')"
                    value="ru">
                  </option>
                </select>
              </div>
<!--              <div class="input_iin_container">-->
<!--                <div :class="[account.password.length > 0 ? activePassClass : '', basicPassClass]">-->
<!--                  <input :type="showPassword ? 'text' : 'password'"-->
<!--                         v-model="account.password"-->
<!--                         class="input_pass_new"-->
<!--                         :placeholder="$t('Password')"-->
<!--                         @blur="$v.account.password.$touch()"-->
<!--                  />-->
<!--                  <div class="input_pass_new_open" v-show="showPassword" @click="showPassword = false"/>-->
<!--                  <div class="input_pass_new_close" v-show="!showPassword" @click="showPassword = true"/>-->
<!--                </div>-->
<!--                <span v-if="$v.account.password.$dirty && $v.account.password.$invalid"-->
<!--                      class="text-danger">{{ $t('Password_is_required!_At_least_8_characters') }}!</span>-->
<!--              </div>-->
<!--              <div class="input_iin_container">-->
<!--                <div :class="[account.password_confirmation.length > 0 ? activePassClass : '', basicPassClass]">-->
<!--                  <input :type="showPasswordRepeat ? 'text' : 'password'"-->
<!--                         v-model="account.password_confirmation"-->
<!--                         class="input_pass_new"-->
<!--                         :placeholder="$t('Repeat_password')"-->
<!--                         @blur="$v.account.password_confirmation.$touch()"-->
<!--                  />-->
<!--                  <div class="input_pass_new_open" v-show="showPasswordRepeat" @click="showPasswordRepeat = false"/>-->
<!--                  <div class="input_pass_new_close" v-show="!showPasswordRepeat"-->
<!--                       @click="showPasswordRepeat = true"/>-->
<!--                </div>-->
            </div>
            <span v-if="this.account.password !== this.account.password_confirmation" class="text-danger">{{ $t('Passwords_must_match') }}!</span>
            <div class="sing_up_agreement">
              <input type="checkbox" id="agreement" v-model="account.agreement" @blur="$v.account.agreement.$touch()"/>
              <label for="agreement">{{ $t('I_agree_to_the_Terms_of_Use') }}</label>
              <span v-if="$v.account.agreement.$dirty && $v.account.agreement.$invalid"
                    class="text-danger">{{ $t('Agreement') }}!</span>
            </div>
            <el-button
              @click="signup"
              class="authorization_btn_next w-100"
              type="danger"
              :style="{ opacity: disabledFurtherButton ? '0.8' : '1' }"
              :disabled="disabledFurtherButton"
              :loading="registerLoading"
            >{{ $t('Further') }}</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import VueMask from 'v-mask';
import {integer, maxLength, minLength, required} from "vuelidate/lib/validators";
import {customEmail} from "@/validators";

export default {
  name: 'Login',
  data() {
    return {
      registerLoading: false,
      basicClass: 'sing_up_input_container',
      activeClass: 'sing_up_input_container_active',
      // basicPassClass: 'input_pass_container_signup',
      // activePassClass: 'input_pass_container_signup_active',
      basicNotificationClass: 'sing_up_notification_container',
      activeNotificationClass: 'sing_up_notification_container_active',
      // showPassword: false,
      // showPasswordRepeat: false,
      email: '',
      loading: false,
      showModal: false,
      phone: '',
      account: {
        last_name: '',
        first_name: '',
        iin: '',
        email: '',
        phone: '',
        notification_language: '',
        // password: '',
        // password_confirmation: '',
        agreement: false,
      },
      errorsMessages: {},
      showEmailError: false,
      showIINError: false,
      showPhoneError: false,
    }
  },
  beforeCreate() {
    Vue.use(VueMask);
  },
  mounted() {
    // this.showPaymentModal({
    //   "access_token": "NS29L6CK6ZC0EXUABT51BR",
    //   "expires_in": "1200",
    //   "refresh_token": "",
    //   "scope": "payment",
    //   "token_type": "Bearer"
    // }, '12412412', 1500);
  },
  computed: {
    disabledFurtherButton() {
      return false;
      // return this.$route.params.tariff === undefined;
    }
  },
  methods: {
    signup() {
      this.$v.$touch();

      if (!this.account.agreement) {
        Vue.toastr({
          message: 'Error',
          description: 'Вы не приняли условия пользовательского соглашения',
          type: 'error'
        })
        return;
      }

      if (this.$v.account.$invalid) {
        Vue.toastr({
          message: 'Error',
          description: 'Ошибка при заполнении данных',
          type: 'error'
        })
        return;
      }

      let account = {}
      Object.assign(account, this.account)
      account.phone = this.phone
      // this.account.password = this.account.iin
      // const tariff = this.$route.params.tariff

      this.registerLoading = true
      // this.$http.post(API_ROOT + `/api/auth/register?tariff=${tariff}`, account)
      this.$http.post(API_ROOT + '/api/auth/register?tariff=2', account)
        .then((res) => {
          if (res?.body?.message === 'payment_required') {
            this.showPaymentModal(res?.body?.auth, res?.body?.invoice, res?.body?.price)
          }
        })
        .catch((err) => {
          this.errorsMessages = err.body.errors

          if ('email' in this.errorsMessages) {
            this.showEmailError = true
          }
          if ('iin' in this.errorsMessages) {
            this.showIINError = true
          }
          if ('phone' in this.errorsMessages) {
            this.showPhoneError = true
          }

          if (!('email' in this.errorsMessages || 'phone' in this.errorsMessages || 'iin' in this.errorsMessages)) {
            Vue.toastr({
              message: 'Error',
              description: err.body.message,
              type: 'error'
            });
          }

        }).finally(() => {
          this.registerLoading = false
        })
    },
    showPaymentModal(auth, invoice, amount) {
      if (window.halyk) {
        this.$nextTick(() => {
          window.halyk.showPaymentWidget(this.createPaymentObject(auth, invoice, amount), (cb) => {
            if (cb.success) {
              this.$router.push({ name: 'success' })
            }
          })
        });
      }
    },
    createPaymentObject(auth, invoiceId, amount) {
      const paymentObject = {
        invoiceId: invoiceId,
        backLink: window.CLIENT_ROOT + "/success",
        failureBackLink: window.CLIENT_ROOT +  "/failure",
        postLink: window.API_ROOT + "/api/payment-response",
        failurePostLink: window.CLIENT_ROOT + `/payment-error/${invoiceId}`,
        language: "rus",
        description: "Оплата в интернет магазине",
        accountId: "1",
        // terminal: "67e34d63-102f-4bd1-898e-370781d0074d",
        terminal: "4d5829eb-caf4-476d-be10-4b92d864d907",
        amount: amount,
        currency: "KZT",
        data: '{"statement":{"name":"test","invoiceID":"' + invoiceId + '"}}',
        cardSave: true
      };
      paymentObject.auth = auth;
      return paymentObject;
    },
    enforcePhoneFormat() {
      this.showPhoneError = false

      let x = this.account.phone
        .replace(/\D/g, "")
        .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

      this.phone = x[0]
    },
  },
  validations: {
    account: {
      iin: {
        required,
        minLength: minLength(12),
        maxLength: maxLength(12),
        integer,
      },
      last_name: {
        required,
        minLength: minLength(2)
      },
      first_name: {
        required,
        minLength: minLength(2)
      },
      email: {
        required,
        customEmail
        // email,
      },
      phone: {
        required,
        minLength: minLength(17),
      },
      notification_language: {
        required
      },
      // password: {
      //   required,
      //   minLength: minLength(8)
      // },
      // password_confirmation: {
      //   required,
      //   minLength: minLength(8)
      // },
      agreement: {
        required
      }
    },
  }
}
</script>
